@import "./partials/normalize";
@import "./partials/variables";
// @import "./partials/iconfont";
// @import "./partials/sprite";


html, body{
	height: 100%;
	margin:0;
	padding:0;
}

body{
	font-family: $fontbasic, sans-serif;
	font-size:20px;
	color: $textcolor;
	line-height:1.6;
	font-weight: 400;
	overflow-x:hidden;
}

img{
	max-width: 100%;
}


// Убираем желтую обводку в Chrome у разных элементов
button, 
button:active, 
button:focus,
select, 
select:active, 
select:focus,
input, 
input:active, 
input:focus,
   {
    outline: none;
    border:none;
    box-shadow: none;
}

// Убираем пунктирную рамку у активных элементов
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: 0!important;
	outline-color: transparent!important;
	outline-width: 0!important;
	outline-style: none!important;
	box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;
} 

// Убираем желтый фон в Chrome при автозаполнении (элементы формы)
@-webkit-keyframes autofill {
    to {
        color: #textcolor;
        background: #fff;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}


// Убираем стрелки с числового поля
input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

// End

a{
	display: block;
	text-decoration: none;
	transition: all 0.5s ease-out;
	color: #1006ba;
}

a:hover {
	text-decoration: none;
	transition: all 0.5s ease-out;
	color: $yellow;
}

ul, li{
	padding:0; 
	margin:0;
	display: block;
}

label{
	line-height: 1.2;
}


h1, h2, h3, h4{
	// font-family: "Noto Serif", serif;
	font-family: $fontheader, serif;
	color: $headercolor;
	font-weight: 400;
}

h1{
	font-size: 34px;
	line-height:1.3;
	margin-top:0;
	margin-bottom: 30px;
	text-align:center;
}

h2, h3{
	line-height:1.3;
	// font-weight: 700;
	color:#303a5d;
}

h2{
	font-size:30px;
	margin-top: 0;
	margin-bottom: 40px;
}

h3{
	font-size: 22px;
	margin-bottom: 40px;
}

.container-fluid{
	max-width: 1566px;
}
.header-colored{
	// color: $brightblue;
	display:block;
}

.subtitle{
	margin-top:-20px;
	margin-bottom:30px;
	opacity:.6;
}


.icon:before{
	padding-right:8px;
	vertical-align: bottom; //- когда иконки - строчные элементы, то требуется опустить их вниз, чтобы не висели
}

.nowrap{
	white-space: nowrap;
}

.no-padding{
	padding:0;
}


.btn {
    display: inline-block;
    border:none;
    // border-radius:100px;
    letter-spacing: 2px;
    text-align: center;
    // background: $yellow;
    font-size:14px;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: $headercolor;
    // padding:18px 32px;
    padding: 12px 28px;
    // border-radius: 4px;
}

.btn--half{
    position: relative;
    z-index:0;
    transition:all .3s ease-out;
    border-radius:4px;
    // border: 1px solid #ccc;
    &:before{
	    content: '';
	    position: absolute; top: 0; left: 0;
	    width: 50%;
	    height: 100%;
	    background: $yellow;
	    z-index: -1;
	    transition:all .3s ease-out;	
    }
    &:hover{
    	color:#111;
    }
    &:hover:before{
    	width:100%;
    	background: $yellowhover;
    	transition:all .3s ease-out;
    }
}

.btn--round{
    width:50px;
    height:50px;
    border-radius: 100px;
    background: #ffd232;
}


.btn-small-round{
	width:32px;
	height:32px;
	background:$yellow;
	border-radius:50px;
}

.btn-small-round img{
	transform: rotate(-90deg);
	margin:auto;
	margin-top: 11px;
}

.btn.focus, .btn:focus {
    box-shadow: none;
}

.btn-arrow:hover span:after{
	transform:translateX(10px);
	transition:all .3s ease-out;
}

.btn-arrow span:after{
	content:url('../img/arrow-right.svg');
	display: inline-block;
	margin:auto;
	margin-left:10px;
	transition:all .3s ease-out;
}

// .btn-arrowbottom:hover span:after{
// 	transform:translateX(10px);
// 	transition:all .3s ease-out;
// }

.btn--arrowbottom span:after{
	content:url('../img/arrow-bottom-small.svg');
	display: inline-block;
	margin:auto;
	margin-left:10px;
	transition:all .3s ease-out;
}

.section-padding{
	padding:70px 0;
}

.section-padding-top{
	padding:70px 0 0 0;
}

.box-text{
	max-width:420px;
	margin:auto;
	margin-bottom:30px;
}

.img-center{
	display: block;
	margin:auto;
}


/*--------------MEDIA-----------------*/

@media screen and (min-width: 576px){
	h1{
		font-size: 40px;
	}
	.section-padding{
		padding:120px 0;
	}
	.section-padding-top{
		padding:120px 0 0 0;
	}
}

@media screen and (min-width: 768px){
	h1{
		font-size: 52px;
		margin-bottom: 50px;
	}
	h2{
		font-size:36px;
	}

	h3{
		font-size: 28px;
	}
	// h2{
	// 	font-size:48px;
	// 	margin-bottom: 80px;
	// }
	.subtitle{
		margin-top:-30px;
		margin-bottom:50px;
	}
}

@media screen and (min-width: 992px){
	h2{
		font-size:42px;
	}
}


/*-------------- Preloader-----------------*/
#preload {
	position: absolute;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 999999;
}

#over {
	width: 200px;
	height: 200px;
	position: absolute;
	left: 50%;
	top: 50%;
	background-image: url('../img/preloader.gif');
	background-repeat: no-repeat;
	background-position: center;
	margin: -100px 0 0 -100px;
}

/*-------------- End Preloader-----------------*/

/*-------------- MENU-----------------*/

.navbar{
	z-index:1000;
	width:100%;
	display:flex;
	width:100%;
	justify-content: space-between;
	align-items:center;
	padding:20px 20px;
	color:#fff;
}

.navbar--absolute{
	position:absolute;
}

.navbar--relative{
	position:relative;
}

.navbar--light{
	// position:relative;
	// background: #fff;
	// padding:20px 40px;
	// margin-bottom:100px;
}

.navbar--dark{
	position:absolute;
}


.header__left,
.header__right {
	display:flex;
	align-items:center;
}


.navbar--dark .header__left{
	// width: 144px;
 //    height: 60px;
	background:url('../img/logo-white.svg') center center no-repeat;
	background-size:contain;	
}

.navbar--light .header__left {
    background:url('../img/logo-blu.svg') center center no-repeat;
    background-size:contain;	
}

.header__left {
	width: 100px;
    height: 60px;
    margin-right: 20px;
    background-size:contain;
}


.header__group{
	padding-left:30px;
	font-size: 14px;
	letter-spacing:1px;
	opacity:.6;
	line-height: 1.1;
	width:155px;
	display:none;
}

.header__logo{
  display: flex;
  height: 100%;
  align-items: center;
}

// .header__logo {
//     width: 100px;
//     height: 40px;
//     // background-image: url("../img/logo.svg");
//     background-size: contain;
//     background-repeat: no-repeat;
//     position: relative;
// }

.menu__logo{
	width:64px;
	max-width:auto;
}

.menu{
	visibility: hidden;
	opacity: 0;
	display:flex;
	flex-direction:column;
	position:fixed;
	z-index:1;
	top:0;
	right:-100%;
	height:100%;
	justify-content: flex-end;
	align-items: center;
	letter-spacing: 1px;
	// transition: all 2s ease-in-out;
}

.menu-list{
	display:flex;
	flex-direction:column;
	justify-content: space-around;
	align-items: center;
}

.menu-list li{
	margin-right:40px;
}

.nav-link{
	color:#fff;
	position:relative;
	// transition: all .3s ease-out;
	&:hover{
		color:#fff;
	}
	&:hover:after{
		// color:$yellow;
		color:#fff !important;
		width:100%;
		transition: all .3s ease-out;
	}
}

.nav-link:after{
	content:"";
	height:2px;
	width:0;
	background:#fff;
	margin:auto;
	display: block;
	position:absolute;
	bottom:-4px;
	left:0;
	right:0;
	transition: all .3s ease-out;
}

.navbar--light .nav-link{
	color:$headercolor;
	font-weight:400;
	&:after{
		background:$headercolor;
	}
	&:hover:after{
		// color:$yellow;
		color:$headercolor !important;
	}
}

.menu__phone{
	padding-right:8px;
	font-size: 22px;
	font-family: $fontheader, serif;
	letter-spacing:0;
	font-weight: 700;
	&:before{
		font-size:18px;
		padding-right:0;
		line-height:1.8;
	}
}

.menu__contacts{
	display:flex;
	justify-content: space-between;
	align-items: center;
	// margin-right:40px;
	&__icons{
		display: flex;
		justify-content: space-around;
		align-items:center;
		width: 140px;
	}
	&__icons img{
		display: block;
		width:auto;
		margin:auto;
		height:24px;
		padding-right:0;
		padding-left:5px;
	}
}


//- Menu styles for small screens
.menu--visible{
	font-size: 26px;
	font-family:$fontheader;
	color:#fff;
	display:flex;
	justify-content: center;
	padding-top:60px;
	visibility: visible;
	opacity:1;
	transition: right .4s ease-in-out;
	width: 100%;
	min-width: 320px;
	right:0;
	background: rgba(53, 113, 165, 0.9);
	& .menu-list{
		margin-bottom:20px;
	}

	& .menu-list li{
		margin: 0 0 20px 0;
	}
	& .menu__contacts{
		flex-direction: column;
		margin-right:0;
		margin-bottom: 40px;
	}
	& .menu__phone{
		font-size:24px;
		margin-bottom:12px;
	}
	& .menu__cta{
		margin-left:0;
		margin-top:10px;
	}
	& .nav-link{
		color:#fff;
	}
	& .nav-link:after{
		background:#fff;
	}
}

.menu-button{
	width: 30px;
	height:30px;
	position:relative;
	background:transparent;
	border:none;
	padding:0;
	z-index:2;
}


.menu-button span{
	display: block;
	background: #fff;
	opacity: 1;
}

.menu-button span{
	height: 2px;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	z-index: 2;
}

.navbar--light .menu-button span{
	background:$headercolor;
}

.menu-button--to-open span + span {
    margin-top: 7px;
}

.menu-button--to-open span{
  &:nth-child(1){
    width: 100%;
  }
  &:nth-child(2){
     width: 70%;
     margin-left:auto;
     margin-right:auto;
  }
  &:nth-child(3){
    width: 100%;
  }
}

.menu-button--to-close{
	position: fixed;
	right: 40px;
}

.menu-button--to-close span{
  &:nth-child(1){
	transform: rotate(45deg);
	z-index: 2
  }
  &:nth-child(2){
	opacity:0;
  }
  &:nth-child(3){
	margin-top:-4px;
	transform: rotate(-45deg);
  }
}

.navbar--light .menu-button--to-close span{
	background:#fff;
}

@media screen and (min-width: 576px){
	.subtitle{
		letter-spacing: 3px;
	}
}

@media screen and (min-width: 768px){
	.header__group{
		display:block;
	}
	.menu__cta{
	    padding: 12px 34px;
	    font-size: 16px;
	}
	.navbar{
		padding:20px 60px;
	}
	.navbar--light{
		padding:20px 40px;
	}
	.navbar--small{
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.menu--visible{
		width:50%;
	}
}

@media screen and (min-width: 1200px){
	.navbar--light{
		margin-bottom:100px;
	}
		.menu__logo{
		width:auto;
	}
	.header__left {
	    width: 144px;
    }
	.menu-button{
		display:none;
	}
	.menu{
		position:static;
		flex-direction: row;
		visibility: visible;
		background-color:transparent;
		opacity: 1;
		max-width:1150px;
		left:0;
		width:100%;
		font-size:18px;
		font-weight:700;
		letter-spacing: 1px;
	}
	.menu-list{
		flex-direction: row;
	}
}

/*--------------MENU END------------------*/



/*-------------- HOME MAIN -----------------*/

.home-main{
	position: relative;
	width:100%;
	height: 100%;
	min-height:550px;
	max-height:700px;
	color:#fff;
	background: #7aacd7 url('../img/bg-home-mobile.jpg') bottom center no-repeat;
	background-size:cover;
}


.home-main__logo{
	margin-bottom:40px;
}

.home-main__content{
	display: flex;
	flex-direction: column;
	justify-content:center;
	max-width:100%;
	height:100%;
	line-height:1.3;
	padding:100px 60px 100px 60px;
}

.home-main__h1{
	color:#fff;
	text-align: left;
	font-size:22px;
	font-weight: 400;
	font-family: $fontbasic, sans-serif;
	margin-bottom:0;
	text-align:center;
}

.home-main__arrow-wrap{
	margin-left:auto;
	margin-right: auto;
}

.home-main__arrow{
	margin-top:40px;
    height: 60px;
    width: 60px;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.home-main__arrow img{
	transition: all 0.5s ease-out;
	height:20px;
}

.home-main__arrow:hover img{
	transform: translateY(10px);
	transition: all 0.5s ease-out;
}

.btn--round{
	position: relative;
}



@media screen and (min-width: 576px){
	.home-main__logo{
		max-width: 400px;
	}
	.home-main__h1{
		text-align: left;
		padding-left: 80px;
	}
	.last-line{
		display: block;
	}
	.home-main__arrow-wrap{
		padding-left: 80px;
		margin:0;
	}
}

@media screen and (min-width: 768px){
	.home-main{
		background:#7aacd7 url('../img/bg-home-medium.jpg') center center no-repeat;
		background-size: cover;
		height: 100%;
		min-height:550px;
		max-height: 760px;
	}
	.home-main__logo{
		max-width: 450px;
	}
	.home-main__content{
		display: flex;
		flex-direction: column;
		justify-content:center;
		padding:80px 120px 100px 120px;
	}
	// .home-main__h1,
	// .home-main__subtitle{
	// 	max-width: 700px;
	// }
	.home-main__h1{
		font-size:28px;
		line-height:1.2;
	}
	.home-main__subtitle{
		font-size:30px;
	}
}

@media screen and (min-width: 992px){
	.home-main{
		height: 100%;
		min-height:600px;
		max-height:860px;
	}
	.home-main__logo{
		max-width: 500px;
	}
}



@media screen and (min-width: 1200px){
	.home-main{
		min-height:700px;
		max-height:960px;
		background:#7aacd7 url('../img/bg-home.jpg') bottom center no-repeat;
	}
	.home-main__content{
		padding-top:100px;
		padding-left:20%;
	}
	.home-main__logo{
		max-width:720px;
	}
	// .home-main__h1,
	// .home-main__subtitle{
	// 	padding-left:80px;
	// }
}

/*-------------- END HOME MAIN -----------------*/


/*--------------INTRO -----------------*/

.intro__row{
	align-items: center;
}

.intro{
	position:relative;
}

.intro{
	margin-top:60px;
}

.intro__img{
	text-align:center;
}

.intro__text{
	font-family: $fontheader, serif;
	font-size: 24px;
	font-weight: 400;
	color: $headercolor;
	padding:40px 0 0 0;
}

.intro__text span{
	color:$brightblue;
	transition: all .1s ease-out;
}

.img-shadow{
	box-shadow: 17px 17px 30px rgba(0, 0, 0, 0.25);
}


@media screen and (min-width: 576px){
.intro{
	background-size: 100% auto;
}
}

@media screen and (min-width: 768px){
	.intro__text{
		padding:60px 0 120px 0;
	}
}

@media screen and (min-width: 992px){
	.intro{
		background:url('../img/island-bg.jpg') top left no-repeat;
		background-size: 50% auto;
	}
	.intro{
		margin-top:120px;
	}
	}

	@media screen and (min-width: 1200px){
	.intro__text{
		font-size: 36px;
	}
}

/*--------------END INTRO -----------------*/


/*--------------ATMOSPHERE -----------------*/

.atmosphere{
	position:relative;
	&:after{
		content:"";
		position:absolute;
		left:0;
		top:80px;
		width: 30%;
		height:70%;
		display: block;
		background-color: $lightbg;
		z-index:-1;
	}
}

.atmosphere__text {
	max-width: 500px;
	margin:auto;
	margin-bottom: 30px;	
}

.atmosphere__img-wrapper{
	margin-bottom:40px;
}

.atmosphere__img-3{
	margin-bottom:60px;
}


@media screen and (min-width: 768px){
.atmosphere{
	background: url('../img/wish-1.jpg') right top no-repeat;
	background-size:200px;
}
.atmosphere__img-3{
	margin-top:50px;
	margin-bottom:0;
}
}

@media screen and (min-width: 992px){
.atmosphere{
	&:after{
		left:auto;
		right:0;
		width: 550px;
	}
}
.atmosphere__img-wrapper{
	position: absolute;
	left: -400px;
	right: 0;
	text-align: right;
	top:120px;
}
}

@media screen and (min-width: 1200px){
	.atmosphere{
		background-size:auto;
	}
}
/*--------------END ATMOSPHERE -----------------*/


/*--------------HOME SLIDER -----------------*/

.home-slider{
	position:relative;
	overflow: hidden;
}

.home-slider::before {
    background: $lightbg;
    content: "";
    display: block;
    width: 100%;
    height: 65%;
    position: absolute;
    top: 0;
    left: 0;
}

.home-slider__h2{
	text-align: left;
}

.slick-slider{
	position:static;
	padding-top:20px;
}

.slick-arrow{
	width: 40px;
	height: 40px;
	border-radius:50px;
	&:hover{
		opacity:1;
	}
}

.slick-prev{
	position: absolute;
	top:50%;
	background-color:rgba(255,255,255,0.6);
	left:20px;
	z-index:1;
	&:hover img{
		transform:translateX(5px);
	}
}

.slick-next{
	position: absolute;
	top:50%;
	background-color:rgba(255,255,255,0.6);
	right:20px;
	z-index:1;
	&:hover img{
		transform:translateX(5px);
	}
}

.slick-arrow img{
	width: 18px;
	height: auto;
	display: block;
	margin: auto;
	transition: all 0.3s ease-out;
	opacity:0.6;
}

.slick-prev{
	transform:rotate(180deg);
}

.slick-prev:hover {
	transition: all 0.3s ease-out;
}

.slick-prev.slick-arrow:hover img {
	transition: all 0.3s ease-out;
}

.slick-next:hover {
	transition: all 0.3s ease-out;
}


.allbeauty{
	margin-right:-500px;
}


.allbeauty__item{
	position:relative;
	display: block;
	max-height:none;
	max-width: 362px;
	&:hover{
		z-index:1;	
	}
	& img{
		transition: all 0.3s ease-out;
		display: block;
		width:100%;
	}
	&:hover img{
		transform: scale(1.13487,1.13636);
		transition: all 0.3s ease-out;
	}
	&:hover .allbeauty__item__title{
		left:10px;
		bottom:-20px;
		transition: all 0.3s ease-out;
	}
}

.allbeauty__item__inner {
    width: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right:10px;
}

.allbeauty__item__content{
	margin:auto;
}

.allbeauty__item__title{
	position:absolute;
	bottom:0px;
	left:0;
	right:10px;
	font-family: $fontheader, serif;
	font-size:22px;
	line-height:114%;
	color:#fff;
	display:flex;
	justify-content: space-between;
	align-items: center;
	padding-left:20px;
	padding-right:20px;
	padding-bottom:60px;
	color:#fff;
	transition: all 0.3s ease-out;

	&:hover{
		color:#fff;
	}
}


@media screen and (min-width: 576px){
.slick-prev{
	top:0;
	right: 80px;
	left:auto;
	background-color:transparent;
}
.slick-next{
	top:0;
	right: 15px;
	background-color:transparent;
}
.slick-arrow{
	width: 50px;
	height: 50px;
	border: 1px solid rgba(32, 32, 57, 0.38);
	border: 1px solid #666;
	opacity: 0.7
}
.slick-arrow:focus{
		border: 1px solid #666;
}
}

/*--------------END HOME SLIDER -----------------*/

.experience__text{
	max-width: 500px;
	margin:auto;
}

@media screen and (min-width: 768px){
	.intro__text{
		padding:60px 0 120px 0;
	}
}

@media screen and (min-width: 992px){
	.experience{
		position:relative;
		padding-top:60px;
	}
	.experience::after {
	    content: "";
	    display: block;
	    position: absolute;
	    top: 0;
	    left: 50%;
	    bottom: 0;
	    right: 0;
	    background: url(../img/experience-bg.jpg) top right no-repeat;
	    z-index: -1;
	}
	.experience__img {
		position: static;
	}
}

/*--------------END EXPERIENCE -----------------*/


/*-------------- HORIZONS-----------------*/
.horizons{
	background:url('../img/horizons-bg-mobile.jpg') bottom center no-repeat;
	min-height:760px;
	text-align: center;
	padding-top:120px;
}

.horizons__text{
	font-size:28px;
	max-width: 600px;
	margin:auto;
	line-height:1.4;
	margin-bottom: 50px;
	font-family: $fontheader, serif;
	color: $headercolor;
}

@media screen and (min-width: 768px){
	.horizons__text{
		font-size:36px;
	}
	.horizons{
		background:url('../img/horizons-bg.jpg') bottom center no-repeat;
		min-height:950px;
		padding-top:240px;
	}
}
/*-------------- END HORIZONS-----------------*/


/* ////////////////////////////////////////// */
/*-------------- PAGE EXCURSIONS--------------*/
/* ////////////////////////////////////////// */

@import "./partials/excursions";


/*-------------- END PAGE EXCURSIONS--------------*/


/* ////////////////////////////////////////// */
/*-------------- PAGE ONE EXCURSION--------------*/
/* ////////////////////////////////////////// */

.one-excurs{
	overflow: hidden;
}

.one-excurs__content{
	padding-bottom:0;
}

.one-excurs__h1{
	text-align:left;
}

.exc__title-addition{
	display: block;
	margin-top:20px;
	margin-bottom:20px;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 400;
	font-size: 22px;
	color: lighten($textcolor, 10);
}

.btn--allexcurs{
	padding-left:0;
	margin-bottom:20px;
}

.one-excurs__duration{
	position: relative;
	padding:24px 30px;
	margin-bottom:40px;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 400;
	color:lighten($textcolor, 10);
	& span{
		color: $brightblue;
		font-weight: 700;
		font-size:20px;
		padding-left:3px;
	}
	&:after{
		content:"";
		display:block;
		position: absolute;
		left:0;
		top:0;
		right:-300px;
		bottom:0;
		background:$lightbg;
	}
}

.one-excurs__inner{
	position:absolute;
	top:0;
	left:-140px;
	right:-140px;
	width:auto;
	height:100%;
}

.one-excurs__taormina{
	background:url('../img/excursions/taormina-sm.jpg') center center no-repeat;
}

.one-excurs__etnasud{
	background:url('../img/excursions/etna-sud-sm.jpg') center center no-repeat;
}

.one-excurs__etnavine{
	background:url('../img/excursions/etna-vine-sm.jpg') center center no-repeat;
}

.one-excurs__etnanord{
	background:url('../img/excursions/etna-nord-sm.jpg') center center no-repeat;
}

.one-excurs__unknownsicily{
	background:url('../img/excursions/unknown-sicily-sm.jpg') center center no-repeat;
}

.one-excurs__palermo{
	background:url('../img/excursions/palermo-sm.jpg') center center no-repeat;
}

.one-excurs__palermomonreale{
	background:url('../img/excursions/palermo-monreale-sm.jpg') center center no-repeat;
}

.one-excurs__palermocefalu{
	background:url('../img/excursions/palermo-cefalu-sm.jpg') center center no-repeat;
}

.one-excurs__cefalu{
	background:url('../img/excursions/cefalu-sm.jpg') center center no-repeat;
}

.one-excurs__agrigento{
	background:url('../img/excursions/agrigento-sm.jpg') center center no-repeat;
}

.one-excurs__agrigentopiazza{
	background:url('../img/excursions/agrigento-piazzaarmerina-sm.jpg') center center no-repeat;
}

.one-excurs__piazzacaltagirone{
	background:url('../img/excursions/piazzaarmerina-caltagirone-sm.jpg') center center no-repeat;
}

.one-excurs__siracusa{
	background:url('../img/excursions/siracusa-sm.jpg') center center no-repeat;
}

.one-excurs__siracusanoto{
	background:url('../img/excursions/siracusa-noto-sm.jpg') center center no-repeat;
}

.one-excurs__cataniaetna{
	background:url('../img/excursions/catania-etna-sm.jpg') center center no-repeat;
}

.one-excurs__cataniasiracusa{
	background:url('../img/excursions/catania-siracusa-sm.jpg') center center no-repeat;
}

.one-excurs__cataniariviera{
	background:url('../img/excursions/catania-riviera-sm.jpg') center center no-repeat;
}

.one-excurs__noto{
	background:url('../img/excursions/noto-sm.jpg') center center no-repeat;
}

.one-excurs__messina{
	background:url('../img/excursions/messina-sm.jpg') center center no-repeat;
}

.one-excurs__messinatindari{
	background:url('../img/excursions/messina-tindari-sm.jpg') center center no-repeat;
}

.one-excurs__padrino1{
	background:url('../img/excursions/padrino1-sm.jpg') center center no-repeat;
}

.one-excurs__padrino2{
	background:url('../img/excursions/padrino2-sm.jpg') center center no-repeat;
}

.one-excurs__img-wrap{
	margin-bottom:60px;
}

.one-excurs__text{
	padding-bottom:40px;
}

.prices{
	background: url("../img/excursions/one-excursion-bg-mobile.jpg") bottom center no-repeat;
	min-height: 1180px;
	text-align:center;
}

.prices__h2{
	text-align: left;
}

.prices__exc__subtitle{
	text-align: left;
	margin-left:0;
	margin-right:0;
}

.prices__item{
	background: #fff;
	transition: $mytransition;
	position:relative;
	z-index: 0;
	margin:auto;
	margin-bottom:40px;
	// max-width:260px;
	&:before{
		content:"";
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		display: block;
		background: #fff;
		z-index:-1;
		box-shadow: 0px 0px 20px rgba(15,67,129, 0.15);
		transition: $mytransition;
	}
}

.prices__item--style1{
	max-width:260px;	
}

.prices__item--style2{
	max-width:300px;
	padding:30px;	
}

.prices__item:hover{
	&:before{
		top:-8px;
		bottom:-8px;
		left:-8px;
		right:-8px;
		box-shadow: 0 0 30px rgba(15,67,129,.25);
		transition: $mytransition;
		background: #fff;
	}
	& .prices__header{
		transform: translateX(-16px);
		margin-right:-14px;
		transition: $mytransition;
	}
}

.prices__header{
	position: relative;
	z-index: 0;
	padding:20px 20px;
	color:#fff;
	font-size:22px;
	line-height:1.3;
	transition: $mytransition;
}

.prices__header--st1{
	background: $pricestyle1;
}

.prices__header--st2{
	background: $pricestyle2;
}

.prices__info{
	display: flex;
	flex-direction: column;
	padding:30px 20px;
	height:220px;
	justify-content:space-between;
	align-items:center;
}

.prices__npersons{
	font-size:22px;
	& span{
		font-weight: 700;
		font-size:24px;
		color:darken($textcolor, 20);
	}
}

.prices__price{
	color: $brightblue;
	font-size: 32px;
}

.prices__btn{
	background: $yellow;
	margin-top:20px;
	transition: $mytransition;

	&:hover{
		background:$yellowhover;
		transition: $mytransition;
		color:#111;
	}
}

.one-excurs__img-wrap{
	height:300px;
}

.nextexc{
	padding-top:160px;
	padding-bottom:220px;
	display: inline-block;
}

.nextexc__subtitle{
	color:#fff;
	font-size: 14px;
	letter-spacing: 3px;
	text-transform: uppercase;
}

.nextexc__link{
	font-family: $fontheader;
	font-size: 30px;
	color:#fff;
	color:$yellow;
	line-height:1.3;
	position:relative;

	& span:after{
		content:"";
		display: block;
		margin:auto;
		transform:rotate(-90deg);
		height:40px;
		width:40px;
		background:url("../img/arrow-bottom.svg") center center no-repeat;
		background-size:contain;
		transition:$mytransition;
	}
	&:hover span:after{
		transform: rotate(-90deg) translateY(10px);
		transition:$mytransition;
	}
}

@media screen and (min-width: 576px){
	.nextexc{
		padding-bottom:140px;
	}
}

@media screen and (min-width: 768px){
	.prices{
		background: url("../img/excursions/one-excursion-bg.jpg") bottom center no-repeat;
	}
	.prices__item{
		margin-left:8px;
		margin-right:8px;
		margin-top:8px;
	}
	.nextexc{
		padding-bottom:220px;
	}
}

@media screen and (min-width: 992px){
	.one-excurs {
	    margin-top: 100px;
	}
	.one-excurs__content {
	    padding-bottom: 120px;
	}
	.one-excurs__img-wrap{
		height:auto;
	}
	.one-excurs__inner{
		top:0;
		left:0;
		width:600px;
		height:100%;
		background-size:cover;
	}
	.prices__h2{
		text-align:center;
	}
	.prices__exc__subtitle{
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.one-excurs__taormina{
		background:url('../img/excursions/taormina.jpg') center center no-repeat;
	}

	.one-excurs__etnasud{
		background:url('../img/excursions/etna-sud.jpg') center center no-repeat;
	}

	.one-excurs__etnavine{
		background:url('../img/excursions/etna-vine.jpg') center center no-repeat;
	}

	.one-excurs__etnanord{
		background:url('../img/excursions/etna-nord.jpg') center center no-repeat;
	}

	.one-excurs__unknownsicily{
		background:url('../img/excursions/unknown-sicily.jpg') center center no-repeat;
	}

	.one-excurs__palermo{
		background:url('../img/excursions/palermo.jpg') center center no-repeat;
	}

	.one-excurs__palermomonreale{
		background:url('../img/excursions/palermo-monreale.jpg') center center no-repeat;
	}

	.one-excurs__palermocefalu{
		background:url('../img/excursions/palermo-cefalu.jpg') center center no-repeat;
	}

	.one-excurs__cefalu{
		background:url('../img/excursions/cefalu.jpg') center center no-repeat;
	}

	.one-excurs__agrigento{
		background:url('../img/excursions/agrigento.jpg') center center no-repeat;
	}

	.one-excurs__agrigentopiazza{
		background:url('../img/excursions/agrigento-piazzaarmerina.jpg') center center no-repeat;
	}

	.one-excurs__piazzacaltagirone{
		background:url('../img/excursions/piazzaarmerina-caltagirone.jpg') center center no-repeat;
	}

	.one-excurs__siracusa{
		background:url('../img/excursions/siracusa.jpg') center center no-repeat;
	}

	.one-excurs__siracusanoto{
		background:url('../img/excursions/siracusa-noto.jpg') center bottom no-repeat;
	}

	.one-excurs__cataniaetna{
		background:url('../img/excursions/catania-etna.jpg') center bottom no-repeat;
	}

	.one-excurs__cataniasiracusa{
		background:url('../img/excursions/catania-siracusa.jpg') center bottom no-repeat;
	}

	.one-excurs__cataniariviera{
		background:url('../img/excursions/catania-riviera.jpg') center center no-repeat;
	}

	.one-excurs__noto{
		background:url('../img/excursions/noto.jpg') center bottom no-repeat;
	}

	.one-excurs__messina{
		background:url('../img/excursions/messina.jpg') center center no-repeat;
	}

	.one-excurs__messinatindari{
		background:url('../img/excursions/messina-tindari.jpg') center center no-repeat;
	}

	.one-excurs__padrino1{
		background:url('../img/excursions/padrino1.jpg') center center no-repeat;
	}

	.one-excurs__padrino2{
		background:url('../img/excursions/padrino2.jpg') center center no-repeat;
	}

	.next-link:hover{
		letter-spacing: 5px;
		transition:$mytransition;
	}
}

@media screen and (min-width: 1200px){
	.one-excurs {
	    margin-top: 0;
	}
	.one-excurs__inner{
		width:960px;
	}
	.nextexc__link{
		&:hover{
			letter-spacing: 5px;
			transition:$mytransition;
		}
	}
}


/*-------------- END PAGE ONE EXCURSION--------------*/




/*-------------- FAQ--------------*/

.faq__wrap {
    background: url('../img/bg-top-faq.jpg') top center no-repeat;
}

.faq{
	padding-top:200px;
	padding-bottom:120px;
}

.spoiler-wrap{
	font-size:18px;
	line-height: 1.4;
	padding:15px 40px 0 0;
	border-bottom:1px solid #eaeaeb;
	transition: $mytransition;
	& p{
		margin-top:0;
	}
}

.spoiler-wrap.active {
    padding-top:30px;
    transition: $mytransition;
}

.spoiler-head{
	color:darken($textcolor, 40);
	color:$brightblue;
	font-size:20px;
	padding-bottom: 15px;
	padding-right:30px;
	position:relative;
	transition: all 0.5s ease-out;
	&:before{
		content:url('../img/icon-plus.svg');
		width:13px;
		height:13px;
		position:absolute;
		top:0;
		right:-20px;
		line-height:1;
		
	}
}

.active .spoiler-head{
	&:before{
		content:url('../img/icon-minus.svg');
		display: block;
		height:13px;
		width:13px;
	}
}

.spoiler-body{
	padding-bottom:20px;
	padding-right:30px;
}


/*-------------- END PAGE FAQ--------------*/


/*-------------- TRANSFER--------------*/

.transfer-main{
    background: url('../img/bg-transfer-small.jpg') top center no-repeat;
    background-size:contain;
    padding-top:200px;
    padding-bottom:70px;
}

.transfer__section{
	margin-bottom:40px;
}

.transfer__h1{
	text-align: left;
}

.transfer__h2{
	margin-bottom:40px;
	font-size:26px;
	line-height:1.5;
	font-weight: bold;
}

.transfer__subtitle{
	font-size:14px;
	text-transform: uppercase;
	letter-spacing:6px;
	font-weight: 400;
}

.transfer__description{
	max-width: 500px;
	font-size:24px;
}

.transfer-main__location{
	display:block;
}
.transfer__location{
	color: $brightblue2;
}

.btn--alltariffs{
	margin-top:40px;
	margin-bottom:30px;
}

/*--------------HOME SLIDER -----------------*/

.transfer-slider{
	position:relative;
	overflow: hidden;
}

.transfer-destinations{
	padding-top:20px;
	width:1540px;
}

.transfer-destinations .slick-prev{
	top:0;
	right: 65px;
	left:auto;
	background-color:transparent;
}

.transfer-destinations .slick-next{
	top:0;
	right: 15px;
	background-color:transparent;
}

.transfer-destinations .slick-arrow{
	border: 1px solid #666;
	opacity: 0.7
}

.transfer-slider__item{
	position:relative;
	display: block;
	max-height:none;
	&:hover{
		z-index:1;	
	}

	& img{
		transition: all 0.3s ease-out;
		display: block;
		width:100%;
	}
	
	&:hover img{
		transform: scale(1.13487,1.13636);
		transition: all 0.3s ease-out;
	}
	&:hover .allbeauty__item__title{
		left:10px;
		bottom:-20px;
		transition: all 0.3s ease-out;
	}
}

.transfer-slider__item__inner {
    width: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right:20px;
}

.transfer-slider__item__content{
	margin:auto;
}

.transfer-slider__item__title{
	position:absolute;
	bottom:0px;
	left:0;
	right:10px;
	font-family: $fontbasic, serif;
	font-size:22px;
	line-height:114%;
	color:#fff;
	display:flex;
	flex-direction: column;
	padding-left:20px;
	padding-right:20px;
	padding-bottom:60px;
	color:#fff;
	transition: all 0.3s ease-out;

	&:hover{
		color:#fff;
	}
}

/*--------------End Transfer slider -----------------*/


.transfer__left, .transfer__right{
	padding-bottom:70px;
}

.prices-card__wrapper{
	display: flex;
	justify-content: space-between;
	flex-wrap:wrap;
}

.pricesauto__item{
	text-align:center;
	max-width:290px;
	line-height:1.4;
	background: #fff;
	transition: $mytransition;
	position:relative;
	z-index: 0;
	margin:auto;
	margin-bottom:40px;
	&:before{
		content:"";
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		display: block;
		background: #fff;
		z-index:-1;
		box-shadow: 0px 0px 20px rgba(15,67,129, 0.15);
		transition: $mytransition;
	}
}

.pricesauto__header{
	height:65px;
	width:100%;
	background:#7aa0ba;
	display: flex;
	justify-content: center;
	align-items:center;
	padding-left:15px;
	padding-right:15px;
	transition: $mytransition;
	position:relative;
	&:before{
		content:"";
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:#7aa0ba;
		z-index:-1;
		transition: $mytransition;
	}
}

.pricesauto__persons{
	font-size:24px;
	color:$headercolor;
	margin-top: 30px;
	margin-bottom: 20px;

	& span{
		color:#DF3232;
		font-weight: 700;
		font-size: 28px;
	}
}

.pricesauto__model{
	height:64px;
	max-width: 200px;
	margin:auto;
	text-align: center;
}

.pricesauto__direction {
	padding-right:2px;
	font-size:18px;
	color: lighten($textcolor, 20);
}

.pricesauto__iconeuro{
	color:$brightblue;
	font-size: 32px;
}

.pricesauto__number{
	color:$brightblue;
	letter-spacing: -1px;
	font-size: 32px;
}

.pricesauto__btn{
	margin-top:30px;
	margin-bottom: 40px;
}

.pricesauto__item:hover{
	&:before{
		bottom:-8px;
		left:-8px;
		right:-8px;
		box-shadow: 0 0 30px rgba(15,67,129,.25);
		transition: $mytransition;
		background: #fff;
	}
	& .pricesauto__header:before{
		top:-8px;
		left:-8px;
		right:-8px;
		bottom:0;
		transition: $mytransition;
	}
}

@media screen and (min-width: 576px){
	.transfer-main{
	    background: url('../img/bg-transfer-medium.jpg') top center no-repeat;
	    background-size:contain;
	}
	.transfer-destinations{
		padding-top:30px;
	}
	.transfer-destinations .slick-prev{
		top:0;
		right: 80px;
		left:auto;
		background-color:transparent;
	}

	.transfer-destinations .slick-next{
		top:0;
		right: 15px;
		background-color:transparent;
	}

	.transfer-destinations .slick-arrow{
		border: 1px solid #666;
		opacity: 0.7
	}
	.pricesauto__item{
		max-width:340px;
	}
}

@media screen and (min-width: 768px){
	.transfer__subtitle{
		font-size:16px;
	}
	.transfer__h2{
		margin-bottom:50px;
		font-size:34px;
		line-height:1.5;
		font-weight:400;
	}
	.transfer-main{
	    padding-bottom:140px;
	}
}

@media screen and (min-width: 992px){
	.transfer-main{
	    background: url('../img/bg-transfer.jpg') top center no-repeat;
	    background-size:auto;
	}
	.transfer__left{
		position:relative;
		padding-top:80px;
		padding-bottom:80px;
		background: $lightbg;
		background: rgba(215, 231, 246, 0.17);
	}

	.transfer__right{
		position:relative;
		padding-top:80px;
		padding-bottom:80px;
	}

	.transfer__left:before{
		content:"";
		position:absolute;
		top:0;
		left:0;
		width:calc((100% - 1140px) / 2 + 475px);
		height:auto;
		bottom:0;
	}

	.transfer__right:before{
		content:"";
		position:absolute;
		top:0;
		right:0;
		width:calc((100% - 1140px) / 2 + 475px);
		height:auto;
		bottom:0;
	}

	.transfer-taormina:before{
		background: url('../img/transfer/transfer-img1.jpg') top left no-repeat;	
	}

	.transfer-milazzo:before{
		background: url('../img/transfer/transfer-img2.jpg') top right no-repeat;	
	}

	.transfer-portorosa:before{
		background: url('../img/transfer/transfer-img3.jpg') top left no-repeat;	
	}

	.transfer-siracusa:before{
		background: url('../img/transfer/transfer-img4.jpg') top right no-repeat;	
	}

	.transfer-ragusa:before{
		background: url('../img/transfer/transfer-img5.jpg') top left no-repeat;	
	}

	.transfer-cefalu:before{
		background: url('../img/transfer/transfer-img6.jpg') top right no-repeat;	
	}

	.transfer-portoriposto:before{
		background: url('../img/transfer/transfer-img7.jpg') top left no-repeat;	
	}
	.transfer__left .transfer__h2{
		padding-left:60px;
	}
	.transfer__right .transfer__h2{
		padding-right:60px;
	}
}


/*-------------- END TRANSFER--------------*/




/*-------------------------------------------------------------
---------------------Booking------------------------
--------------------------------------------------------------*/

.page-booking{
	padding-top:200px;
	padding-bottom:120px;
	background: url('../img/bg-bottom-faq.jpg') bottom center no-repeat
}

.urgent-phone {
	text-align:left;
	max-width:430px;
	margin:auto;
}
.urgent-phone img{
	float:left;
	padding-right:20px;
}

.urgent-phone-inner {
    font-size: 22px;
}

/*Все классы, начиающиеся на phone-line*/
[class^="phone-line"]{
	display:block;
}

.booking-form-wrapper{
	padding-top:60px;
	padding-bottom:60px;
}

.urgent-phone{
	color:#111;
}

.phone-line4{
	color:#f7b923;
	font-weight: 700;
}

.orderform .form-group label{
	color:#555;
}

.orderform select,
.orderform input,
.orderform textarea{
	font-size: 18px;
}

select option{
    font-size: 14px;
}

.orderform .form-control:focus{
	border:1px solid $brightblue;
}
.orderform .form-control {
	background: #fff;
	box-shadow: 0 0 5px rgba(15,67,129,.15);
	color:#555;
}

.has-error .form-control, .has-error .form-control:focus, .has-error .form-control:active {
    border: 1px solid red;
    background-color: #f0cbcb;
    color:#000;
}

.info-hotel, .select-departure, .oneway-round, .oneway, .round, .booking-addinfo, .info-person{
    clear:both;
    border-top: 1px solid #ccc;
}

.oneway-round, .cell-group {
    clear:both;
}

.booking-p{
    color: #858585;
    font-size:16px;
    font-style:italic;
}

#addinfo{
	width:100%;
}

.info-hotel, .oneway-round{
    display: none;
} 

.info-contacts{
    font-size: 20px;
    font-weight: bold;
    margin-bottom:40px;
}

#form-to-airport, #form-to-hotel{
    display:none;
}

.orderform{
    padding:50px 40px 80px 40px;
    border-radius:2px;
 	background:rgba(230, 240, 249, 0.9);
 	box-shadow: 0 0 10px rgba(15,67,129,.25);
 	margin-bottom:80px;
}


#orderForm .form-control, #orderFormRu .form-control, #orderFormEn .form-control{
    display:block;
}

#orderForm .form-group label, #orderFormRu .form-group label, #orderFormEn .form-group label{
    width:auto;
}

#date-there.form-control[readonly], #date-back.form-control[readonly]{
    background-color:#fff;
    cursor:default;
}

.ui-widget{
	font-size:12px;
}

.form-group.checkbox {
    clear: both;
}

.checkbox .label-control {
    width: 100%;
}

.has-error.checkbox label {
    color: #b90805;
}


#wbars {
    max-width: 1024px;
    padding: 15px;
    margin: auto;
}

.btnBooking{
    clear:both;
}

.order-err{
    padding:20px;
    border:1px solid #a94442;
    background-color: #f0cbcb;
    color: #a94442;
}

.order-ok{
    padding:20px;
    background-color: #C6D4AD;
    border:1px solid #55800A;
    color: #55800A;
}

.booking-result{
    padding-bottom:60px;
}

@media screen and (min-width: 992px){
	.orderform{
    	padding:60px 80px 80px 100px;
	}
}


/*-------------------------------------------------------------
---------------------Booking Excursion------------------------
--------------------------------------------------------------*/

.form-group-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

#datatitle{
	display: none;
	visibility: hidden;
}

.bookingexc__empty{
	padding:40px 0;
	background:#fff;
	display: block;
}

.bookingexc__info{
	padding-bottom:40px;
	border-bottom:1px solid;
	margin-bottom: 40px;
	border-bottom: 1px solid #d5d5d5;	
}

.form-booking-excursions{
	display: none;
}

.form-group-info label{
    min-width:130px;
    display: inline-block;
    font-size:16px;
}

.form-group-info-input, .datatitle--visible {
    background: transparent;
    border: none;
    font-size: 20px !important;	
    color: $brightblue;
}

@media screen and (min-width: 768px){
	.form-group-info {
		flex-direction: row;
	    align-items: center;
	    margin-bottom: 10px;
	}
	.bookingexc__empty{
		padding:80px;
	}
}

/*--------------END Booking excursion -----------------*/


/*--------------CONTACTS-----------------*/
.contacts{
	position:relative;
	overflow: hidden;
	padding-top:200px;
	padding-bottom:120px;
}

.contacts__text {
    margin-bottom: 60px;
}

.contacts__h1{
	text-align: left;
}

.contacts__phone{
	color:$headercolor;
	font-size: 26px;
}
.form-contacts {
    background: rgba(255,255,255,0.9);
    padding: 40px 20px;
    box-shadow: 0 0 20px rgba(15,67,129,.15);
}


// Общие свойства для подобных форм


.form-group{
	display:flex;
	flex-direction:column;
	margin-bottom: 1rem;
}

.form-group label{
	font-size:16px;
	line-height:1.2;
	margin-bottom:8px;
}

.form-booking label{
	color:rgba(255,255,255,.5);
}

.form-control{
	display: block;
	background:transparent;
	padding: .375rem .75rem;
	line-height: 1.5;
	background-clip: padding-box;
	border:none;
	border-bottom:1px solid #eaeaeb;
	padding-bottom: 10px;
	margin-bottom: 25px;
	width: 100%;

	&::placeholder{
		color:#5d5b5b;
		font-weight: 300;
	}
	&:focus{
		border-bottom:1px solid #333;
	}
}

.form-control__checkbox{
	width:20px;
	height:20px;
}


.privacy__wrap{
	padding-top:20px;
	padding-bottom:20px;
}

.privacy__text{
	font-size:16px;
	font-weight:400;
}

.link-privacy {
    display: inline;
    opacity:.8;
}

.link-privacy--light{
	color:#fff;
	opacity:.5;
	&:hover{
		opacity:1;
	}
}

.default-checkbox_hidden {
    opacity: 0 !important;
}

.rules {
    top: 0;
    left: 0;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
	font-size: 1rem;
	line-height: 1.5;
}

.custom-control-indicator{
	color: #26a69a;	
}

.custom-control.custom-checkbox{
  .custom-control-input:checked ~ .custom-control-indicator{
    border-color: var(--color);
    transform: rotateZ(45deg) translate(1px, -5px);
    width: 10px;
    border-top: 0 solid #fff;
    border-left: 0 solid #fff;
  }
  .custom-control-indicator{
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    transition: .3s;
  }
}

.form-callback__btn{
	float:right;
	margin-top:10px;
}

.ajax-loader {
    display: none;
}

.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
    display: block;
}


.was-validated .form-control:invalid,
.was-validated .form-result{
	display:block;
	border:1px solid red;
}

.was-validated .form-control:invalid ~ .custom-control-indicator{
	border-color: red;
}

.btn--send{
	width:100%;
}

.form-result{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  position: relative;
  display: none;
}

.result_error {
    color: red;
    background-color: #fbdddd;
    padding: 10px 30px 10px 20px;
}

.result_success {
    color: green;
    background-color: #e7f0e7;
    padding: 10px 30px 10px 20px;
    border: 1px solid green;
  }

.submit__wrap{
	display: flex;
	justify-content: space-between;
	align-items:center;
}

.contacts__phone-group{
	display: flex;
	margin-top:40px;
	& i{
		font-size:24px;
	}
}

.contacts__phone{
	padding-right:10px;
	&:before{
		color:$textcolor;
		opacity:.6;
	}
}

.contacts__location{
	font-size:22px;
	margin-top:10px;
	margin-bottom: 40px;
	&:before{
		color:$textcolor;
		opacity:.6;
	}
}

.contacts-message{
	height:100px;
	max-width:100%;
}

.submit__wrap{
	justify-content:flex-end;
}

@media screen and (min-width: 576px){
	.form-contacts {
	    padding: 60px;
	}
}

@media screen and (min-width: 768px){
	.contacts{
		background: url('../img/bg-bottom-faq.jpg') bottom center no-repeat;
	}
	.contacts__text{
		min-height:700px;
	}
}


/*--------------END CONTACTS -----------------*/

/*--------------FOOTER-----------------*/

.footer{
	background: $lightbg;
	padding:60px 0 0 0;
	font-size:16px;
	color:rgba(39, 39, 39, .6);
	letter-spacing: 0.5;

	& a{
		color:rgba(39, 39, 39, .6);
		&:hover{
			color:rgba(39, 39, 39, 1);
		}
	}

	&__phone{
		font-size:20px;
		margin-top: 20px;
		margin-bottom:5px;	
	}

	&__top{
		padding-bottom:60px;
		text-align:center;
	}

	&__bottom{
		color:#fff;
		font-size: 14px;
		text-align: right;
		padding:10px 0px;
		opacity: .4;
		border-top:1px solid rgba(255,255,255,.2);
	}

	&__privacy{
		margin-bottom: 20px;
	}
}

.footer__row{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.footer__col:not(:last-child){
	margin-bottom: 30px;
}
.col-left span{
	display: block;
}

.footer__icons{
	margin:auto;
}

.footer__logo{
	width:144px;
	margin-bottom: 10px;
}

.menu--footer a{
	margin-bottom:5px;
}

@media screen and (min-width: 992px){
	.footer__top{
		text-align: left;
	}
	.footer__row{
		flex-direction: row;
	}
	.footer__icons{
		margin:0;
	}
}


